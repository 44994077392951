<template>
	<PopupLayer>
		<template
			v-slot:body
		>
			<div class=" flex-column  bg-white overflow-hidden radius-20">
				<div class="bg-popup-title pa-10-20 flex-row justify-space-between items-center under-line">
					<h4>송금</h4>
					<v-icon
						@click="cancel"
					>mdi-close-circle</v-icon>
				</div>

				<div
					class=" overflow-y-auto  "
					style="max-height: 500px"
				>
					<template
						v-if="is_holder"
					>
						<div class="pa-10">
							<div class="flex-row justify-space-between items-center">
								<div class="flex-1 font-weight-bold">송금가능금액</div>
								<div class="flex-2"><input class="input-box  size-px-14 font-weight-bold" :placeholder="send_balance | makeComma"  disabled /></div>
							</div>
							<div
								v-if="false"
								class="mt-10 flex-row justify-space-between items-center"
							>
								<div class="flex-1 font-weight-bold">1회 송금한도</div>
								<div class="flex-2 "><input class="input-box  size-px-14 font-weight-bold" :placeholder="shop_info.payment_limit | makeComma"  disabled /></div>
							</div>
							<div
								v-if="false"
								class="mt-10 flex-row justify-space-between items-center"
							>
								<div class="flex-1 font-weight-bold">최소 송금액</div>
								<div class="flex-2 "><input class="input-box  size-px-14 font-weight-bold" :placeholder="shop_info.minimum | makeComma"  disabled /></div>
							</div>
							<div class="mt-10 flex-row justify-space-between items-center">
								<div class="flex-1 font-weight-bold">송금 금액</div>
								<div class="flex-2 ">
									<div class=" justify-space-between ">
										<input v-model="item_send.amount" class=" box flex-1 pa-10" placeholder="금액을 입력하세요" maxlength="8" :rules="[$rules.max(item_send, 'amount', 8)]" type="number" />
										<button
											class="btn-inline btn-primary"
											@click="setAll"
										>전액</button>
										<div
											v-if="false"
											style="width: 40px">천원</div>
									</div>
								</div>
							</div>
							<div
								v-if="false"
								class="mt-10 flex-row justify-space-between items-center"
							>
								<div class="flex-1 font-weight-bold"></div>
								<div class="flex-2 right color-blue justify-space-between">{{ txt_amount }}원</div>
							</div>
							<div class="mt-10 flex-row justify-space-between items-center">
								<div class="flex-1 font-weight-bold">송금 상점명</div>
								<div class="flex-2"><input v-model="item_send.shop_name" class="input-box " placeholder="입금자명을 입력하세요" maxlength="10" disabled/></div>
							</div>
							<div class="mt-10 flex-row justify-space-between items-center">
								<div class="flex-1 font-weight-bold">메모</div>
								<div class="flex-2"><input v-model="item_send.memo" class="input-box " placeholder="메모를 입력하세요" maxlength="20" /></div>
							</div>
						</div>
					</template>

					<template
						v-else
					>

						<div class=" flex-row justify-space-between items-center under-line">
							<div class="flex-1 font-weight-bold">
								<input v-model="item_search.keyword" class=" pa-5-10 flex-1" placeholder="휴대폰 번호" @keyup.enter="getSearch(1)" type="number" :rules="$rules.max(item_search, 'keyword', 12)"/>
							</div>
							<button
								class=" btn-inline btn-success"

								@click="getSearch(1)"
							>검색</button>
						</div>
						<div class=" pa-10">
							<ul
								v-if="items_shop.length > 0"
							>
								<li
									v-for="(shop, s_index) in items_shop"
									:key="'shop_' + s_index"
									@click="setItem(shop)"
									class="pa-10 box mb-10"
									:class="{'bg-gray-light': shop.shop_uid == item_shop.shop_uid}"
								>
									{{ shop.shop_name }}
								</li>
							</ul>
							<empty
								v-else
							></empty>
						</div>
					</template>
				</div>

				<div
					v-if="is_withdrawal"
					class="mt-auto "
				>
					<button
						v-if="is_holder"
						@click="onPin"
						class="btn btn-primary pa-10"
						:disabled="is_to_merchant_withdrawal"
					><slot name="name-confirm">송금</slot></button>
					<button
						v-else
						@click="setHolder"
						class="btn btn-primary pa-10"
						:disabled="!is_select"
					><slot name="name-confirm">선택</slot></button>
				</div>
				<div
					v-else
					class="mt-auto pa-10 text-center top-line bg-red"
				>잔액이 부족하여 송금 할 수 없습니다.</div>
			</div>
		</template>
	</PopupLayer>
</template>

<script>
import PopupLayer from "../Layout/PopupLayer";
import Empty from "../Layout/Empty";
export default {
	name: 'UserSend'
	, components: {Empty, PopupLayer}
	, props: ['user', 'shop_info', 'company_fee']
	, data: function(){
		return {

			item_send: {
				amount: 0
			}
			, item_holder: {}
			, shop_balance: 0
			, items_account: []
			, item_account: {}
			, account_uid: ''
			, item_search: {
				keyword: ''
			}
			, items_shop: [

			]
			, item_shop: {

			}
		}
	}
	,computed: {
		is_to_merchant_withdrawal: function () {
			let t = true
			if (this.is_select && this.is_holder && this.item_send.amount) {
				console.log(Number(this.item_send.amount))
				if (this.shop_balance >= Number(this.item_send.amount)) {
					t = false
				}
			}

			return t
		}

		, txt_amount: function(){
			let t = 0
			if(this.item_send.amount){
				t = this.$common.geKoreanNumber((this.real_amount + '').replaceAll(',', ''))
			}
			return t
		}
		
		, send_balance: function(){
			let t = 0
			if(this.shop_balance){
				t = this.shop_balance
			}
			return t
		}
		, real_amount: function(){
			let t = this.item_send.amount
			// t *= Math.pow(10, 3)
			return t
		}
		, is_withdrawal: function(){
			let t = false

			if(this.send_balance >= this.shop_info.minimum){
				t = true
			}
			return t
		}
		, is_holder: function(){
			let t = false
			if(this.item_send.shop_uid && this.item_send.shop_uid == this.item_shop.shop_uid){
				t = true
			}
			return t
		}
		, is_select: function(){
			let t = false
			if(this.item_shop.shop_uid){
				t = true
			}
			return t
		}
	}
	, methods: {

		postSend: async function(){
			this.$bus.$emit('on', true)

			try {

				const result = await this.$Axios({
					method: 'post'
					, url: 'user/postSend'
					, data: {
						shop_uid: this.item_send.shop_uid
						, amount: this.real_amount
						, my_shop_uid: this.shop_info.shop_uid
						, memo: this.item_send.memo
					}
				})

				if (result.success) {
					this.$emit('click')
				} else {
					this.$bus.$emit('notify', {type: 'error', message: result.message})
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				setTimeout( () => {
					this.$bus.$emit('on', false)
				}, 3000)
			}
		}
		, postHoder: async function(){
			try{
				this.$bus.$emit('on', true)
				let result = await this.$Axios({
					method: 'post'
					, url: 'user/postHolder'
					, data: {
						shop_uid: this.item_send.shop_uid
						, amount: this.real_amount
						, bank_code: this.item_send.bank_code
						, bank_account: this.item_send.bank_account
						, bank_holder: this.item_send.bank_holder
						, depositor: this.item_send.depositor
						, holder: this.item_send.holder
					}
				})
				if(result.success){
					this.is_holder = true
					this.item_holder = result.data
					this.item_send.holder = this.item_holder.realBankOwnerName
				}else{
					throw result.message
				}
			}catch(e){
				this.is_holder = false
				this.item_holder = {}
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, onPin: function(){
			this.$bus.$off('pinCallback')

			this.$bus.$emit('onPin', {
				type: 'check'
			})

			this.$bus.$on('pinCallback', (call) => {
				console.log('pinCallback', call)
				this.$set(this.item_send, 'pin', call)
				this.postSend()
			})
		}
		, cancel: function(){
			if(this.is_holder){
				this.item_shop = {}
			}else{
				this.$emit('cancel')
			}
		}
		, getData: async function(){
			try{
				this.$bus.$emit('on', true)
				let result = await this.$Axios({
					method: 'get'
					, url: 'user/getShopWithdrawalInfo'
					, data: {
						shop_uid: this.shop_info.shop_uid
					}
				})
				if(result.success){
					this.shop_balance = result.data.shop_balance
					this.items_account = result.data.account_list
				}else{
					throw result.message
				}
			}catch(e){
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, setAccount: function(){
			this.items_account.filter( (item) => {
				if(item.uid == this.account_uid){
					this.item_send.bank_code = item.bank_code
					this.item_send.bank_account = item.bank_account
					this.item_send.bank_holder = item.bank_holder
				}
			})
		}
		, getSearch: async function(){
			try{
				this.$bus.$emit('on', true)
				let result = await this.$Axios({
					method: 'get'
					, url: 'user/getSearchShop'
					, data: {
						keyword: this.item_search.keyword
					}
				})
				if(result.success){
					this.items_shop = result.data
				}else{
					throw result.message
				}
			}catch(e){
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, setItem: function(item){
			this.item_shop = item
		}
		, setHolder: function(){
			this.item_send = this.item_shop
		}
		, setAll: function(){

			this.$set(this.item_send, 'amount', this.send_balance)
		}
	}
	, created() {
		console.log(this.shop_info)
		this.getData()
	}
	, watch: {
		real_amount: {
			handler: function(call){
				if(Number(call) > Number(this.send_balance)){
					this.$set(this.item_send, 'amount', '')
					this.$bus.$emit('notify', { type: 'error', message: '송금가능금액을 확인하세요'})
				}else if(Number(call) > Number(this.shop_info.payment_limit)){
					// this.$set(this.item_send, 'amount', '')
					// this.$bus.$emit('notify', { type: 'error', message: '1회 송금한도를 확인하세요'})
				}
			}
		}
	}
}
</script>
<template>
	<PopupLayer>
		<template
			v-slot:body
		>
			<div class=" flex-column  bg-white overflow-hidden radius-20">
				<div class="bg-popup-title pa-10-20 flex-row justify-space-between items-center under-line">
					<h4>계좌생성 &gt; 본인인증</h4>
					<v-icon
						@click="cancel"
					>mdi-close-circle</v-icon>
				</div>
				<div class="full-height overflow-y-auto ptb-50">

				</div>
				<div>
					<button
						class="btn btn-primary ptb-10"
						:disabled="is_disabled"
						@click="postConfirm"
					>본인인증</button>
				</div>
			</div>
		</template>
	</PopupLayer>
</template>

<script>
	import PopupLayer from "../Layout/PopupLayer";
	export default {
		name: 'UserConfirm'
		, components: {PopupLayer}
		, data: function(){
			return {
				type: ''
				, nice_url: ''
				, item: {
					name: ''
					, phone_no: ''
					, tokenVersionId: ''
				}
			}
		}
		, computed: {
			is_disabled: function(){
				let t = true
				if(this.type){
					t = false
				}
				return t
			}
		}
		, methods: {
			cancel: function(){
				this.$emit('cancel')
			}
			, postWallet: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						,url: 'user/postWallet'
						,data: {
							type: this.type
							, enc_data: JSON.stringify(this.item)
						}
					})

					if(result.success){
						this.$bus.$emit('notify', { type: 'success', message: result.message})
						this.$emit('click')
					}else{
						throw result.message
					}
				}catch (e) {
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
		}
		, created() {

		}
	}
</script>
